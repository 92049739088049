import * as React from "react";
import { FunctionComponent } from "react";
import { HeaderStyle } from "./headerStyle";
import { Link } from "gatsby";

export interface HeaderProps {

}

export const Header: FunctionComponent<HeaderProps> = (props: HeaderProps) => {

    return (
        <HeaderStyle>
            <Link to="/" ><img src={require("../../images/logo.svg")} /></Link>

            <aside>
                <a href="tel: 0478 94 45 55" className="phone">0478 94 45 55</a>
                <span className="divider">&bull;</span>
                <a href="mailto: info@vandepittebvba.be" className="email">info@vandepittebvba.be</a>
                <span className="divider">&bull;</span>
                <a href="https://www.facebook.com/vandepitte.bvba/" target="_blank" className="facebook">
                    <img src={require("../../images/facebook.svg")} />
                </a>
            </aside>
        </HeaderStyle>
    );
};
