import styled from "styled-components";

export const HeaderStyle = styled.div`
    padding: 80px 0 120px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    
    img {
        height: 110px;
    }
    
    .facebook img {
        height: 24px;
    }

    a {
        color: white;
        text-decoration: none;
        font-weight: 100;
        font-size: 22px;
    }

    aside {
        display: flex;
        align-items: center;
    }

    .divider {
        color: #FDD617;
        margin: 0 40px;
    }

    @media (max-width: 1100px) {
        a {
            font-size: 18px;
        }

        img {
            height: 80px;
        }
    }

    @media (max-width: 925px) {
        flex-direction: column;

        padding-bottom: 40px;

        aside {
            margin-top: 25px;
        }

        & > img {
            margin-bottom: 30px;
        }
    }


    @media (max-width: 590px) {
        .divider {
            margin: 0 10px;
        }
    }
    
    @media (max-width: 435px) {
        a {
            font-size: 14px;
        }
    }
`;
