import styled from 'styled-components';

export const IndexStyle = styled.div`
    margin: 0 auto;
    max-width: 1400px;
    padding: 0 40px;

    main {
        a {
            color: white;
            font-style: italic;
        }
    }

    @media (max-width: 480px) {
        padding: 0 20px;
        width: calc(100vw - 40px);
    }
`;